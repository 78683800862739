@tailwind base;
@tailwind components;
@tailwind utilities;
/* src/index.css or a new CSS file */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
h1,h2,h3,h4{
  margin-bottom: 1.25rem;
}
p{
  margin-bottom: 1rem;
  line-height: 30px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bannerSlider .swiper-pagination {
  text-align: right;
  bottom: 50px !important;
  right: 50px !important;
  left: auto !important;
}
.bannerSlider .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border-radius: 0;
  margin: 0 10px !important;
  background: white;
  opacity: 0.5;
}
.bannerSlider .swiper-pagination-bullet-active {
  background: #7c675c;
}

.regFeatureImage{
  background-image: url('./assets/img/5.jpg');
  background-size: cover;
  background-position: center;
}
.regContentImage{
  background-image: url('./assets/img/9.jpg');
  background-size: cover;
  background-position: center;
}
.accordion-item {
  border-bottom: 1px solid #e2e8f0;
}

.accordion-title {
  display: block;
  padding: 1rem;
  cursor: pointer;
}

.accordion-toggle {
  display: none;
}

.accordion-toggle:checked + .accordion-title {
  background-color: #f0f4f8;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-toggle:checked + .accordion-title + .accordion-content {
  max-height: 100vh;
}
.terms_and_conditions ul li{
  margin-bottom: 1em;
}
.registratioBanners{
  background-image: url(./assets/img/registration-banner.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
}
